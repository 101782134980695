
export const state = {
    user_id: localStorage.getItem('user_id'),
    username: localStorage.getItem('username'),
    access_token: localStorage.getItem('access_token'),
    refresh_token: localStorage.getItem('refresh_token'),
}

export const mutations = {
    setUser(state, user_id) {
        state.user_id = user_id;
    },
    setUsername(state, username) {
        state.username = username;
    },
    setAccessToken(state, token) {
        state.access_token = token;
    },
    setRefreshToken(state, token) {
        state.refresh_token = token;
    },
}


export const actions = {

    // Logs out the current user.
    logout() {
        // localStorage.removeItem('user_id');
        // localStorage.removeItem('username');
        // localStorage.removeItem('access_token');
        // localStorage.removeItem('refresh_token');
        // localStorage.removeItem('expire');
        localStorage.clear();
    },

    
}